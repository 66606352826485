import React, { useEffect, useRef } from "react"
import { useState } from "react"
import heroImg from "./h1_hero1.jpg"
import hero2 from "./h1_hero2.jpg"
import { Link } from "react-router-dom"
import "./Slider.css"
import { useTranslation } from "react-i18next"

export const Slider = (): JSX.Element => {
  const [t, i18n] = useTranslation();
  
  const sliderImages = [
    // {
    //   title: t('slide0Title'),
    //   url: 'https://api.roundtable-act.am/uploads/main_slide1_f2edcb3c46.jpg',
    //   intro: t('slide0Intro'),
    //   action_text: null,
    //   action_link: null
    // },
    {
      title: t('slide1Title'),
      url: 'https://api.roundtable-act.am/uploads/slide1_ba77f1e01d.jpg',
      intro: t('slide1Intro'),
      action_text: t('seeProgrammes'),
      action_link: t('slide1Link')
    },
    {
      title: t('slide2Title'),
      url: 'https://api.roundtable-act.am/uploads/slide2_2d86b12a52.jpg',
      intro: t('slide2Intro'),
      action_text: t('seeProgrammes'),
      action_link: t('slide2Link')
    },
    {
      title: t('slide3Title'),
      url: 'https://api.roundtable-act.am/uploads/slide3_c12048fb44.jpg',
      intro: t('slide3Intro'),
      action_text: t('seeProgrammes'),
      action_link: t('slide3Link')
    },
    {
      title: '',
      url: 'https://api.roundtable-act.am/uploads/slide4_94d0fc7eb5.jpg',
      intro: '',
      action_text: t('seeProgrammes'),
      action_link: t('slide4Link')
    },
    {
      title: '',
      url: 'https://api.roundtable-act.am/uploads/slide5_9ad21bef48.jpg',
      intro: '',
      action_text: t('seeProgrammes'),
      action_link: t('slide4Link')
    },
    {
      title: '',
      url: 'https://api.roundtable-act.am/uploads/slide6_35df24e1ae.jpg',
      intro: '',
      action_text: t('seeProgrammes'),
      action_link: t('slide4Link')
    }
  ]

  const [activeImageNum, setCurrent] = useState<number>(0)
  const length = sliderImages.length
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Ref to hold interval ID

  const nextSlide = () => {
    setCurrent(activeImageNum === length - 1 ? 0 : activeImageNum + 1)
  }

  const prevSlide = () => {
    setCurrent(activeImageNum === 0 ? length - 1 : activeImageNum - 1)
  }

  // Autoplay functionality using useEffect
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 5000); // Change 3000 to your desired interval (in milliseconds)

    return () => clearInterval(intervalRef.current!); // Cleanup function to stop autoplay on unmount
  }, [length, nextSlide]); // Ensure dependencies are up-to-date
  

  // if (!Array.isArray(sliderImages) || sliderImages.length <= 0) {
  //   return <></>
  // }

  return (
    <div className="swiper-container hero-slider">
      <div className="container-fluent h-100">
        <div className="swiper-wrapper">
          {sliderImages.map((currentSlide, ind) => {
            return (
              <React.Fragment key={currentSlide.url}>
                {ind === activeImageNum && (
                  <div
                    className="swiper-slide hero-content-wrap"
                    key={currentSlide.url}
                  >
                    <img src={currentSlide.url} alt="" />
                    <div className="hero-content-overlay position-absolute w-100 h-100">
                      <div className="container h-100">
                        <div className="row h-100">
                          <div className="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-start">
                            <header className="entry-header">
                              <h4>{currentSlide.title}</h4>
                            </header>
                            <div className="entry-content mt-4">
                              <p>
                                {currentSlide.intro}
                              </p>
                            </div>
                            {
                              currentSlide.action_link ? (
                                <footer className="entry-footer d-flex flex-wrap align-items-center mt-5">
                                <Link to={currentSlide.action_link} className="btn orange-border">
                                  {currentSlide.action_text}
                                </Link>
                              </footer>

                              ) : null
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )
          })}
        </div>
        <div
          className="swiper-button-next flex justify-content-center align-items-center"
          onClick={nextSlide}
        >
          <span>
            <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z" />
            </svg>
          </span>
        </div>
        <div
          className="swiper-button-prev flex justify-content-center align-items-center"
          onClick={prevSlide}
        >
          <span>
            <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M1203 544q0 13-10 23l-393 393 393 393q10 10 10 23t-10 23l-50 50q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23z" />
            </svg>
          </span>
        </div>
      </div>
    </div>
  )
}
